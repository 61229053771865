const cards = [
    {
        key: '小铃久绘-日常',
        name: '小铃久绘-日常',
        picture: '/image/80000000.jpg',
        effect: (
            <>
                [怪兽|效果] 天使/神<br />
                [★12] 1000/0<br />
                这个卡名的②③的效果1回合各能使用1次。这张卡不能作为超量召唤的素材。<br />
                ①：这张卡可以不用解放作通常召唤。<br />
                ②：这张卡在墓地存在的场合，将这张卡加入手卡才能发动。从手卡把1只「小铃久绘-日常」以外的「小铃久绘」怪兽召唤。<br />
                ③：这张卡召唤成功的场合才能发动。从卡组把1张「小铃久绘」魔法·陷阱卡加入手卡。<br />
            </>
        )
    },
    {
        key: '小铃久绘-女仆',
        name: '小铃久绘-女仆',
        picture: '/image/80000100.jpg',
        effect: (
            <>
                [怪兽|效果] 天使/神<br />
                [★12] 1000/0<br />
                这个卡名的②③的效果1回合各能使用1次。这张卡不能作为超量召唤的素材。<br />
                ①：这张卡可以不用解放作通常召唤。<br />
                ②：这张卡在墓地存在的场合，将这张卡加入手卡才能发动。从手卡把1只「小铃久绘-日常」以外的「小铃久绘」怪兽召唤。<br />
                ③：这张卡召唤成功的场合才能发动。从卡组把1张「小铃久绘」仪式怪兽卡加入手卡。<br />
            </>
        )
    },
    {
        key: '小铃久绘-元气',
        name: '小铃久绘-元气',
        picture: '/image/80000200.jpg',
        effect: (
            <>
                [怪兽|效果] 天使/神<br />
                [★12] 1000/0<br />
                这个卡名的②③的效果1回合各能使用1次。这张卡不能作为超量召唤的素材。<br />
                ①：这张卡可以不用解放作通常召唤。<br />
                ②：这张卡在墓地存在的场合，将这张卡加入手卡才能发动。从手卡把1只「小铃久绘-日常」以外的「小铃久绘」怪兽召唤。<br />
                ③：这张卡召唤成功的场合才能发动。从卡组把1张「小铃久绘」卡送去墓地。<br />
            </>
        )
    },
    {
        key: '绘绘学歌回',
        name: '绘绘学歌回',
        picture: '/image/80001000.jpg',
        effect: (
            <>
                [魔法]<br />
                ①：从卡组把1只「小铃久绘」怪兽加入手卡。<br />
            </>
        )
    },
    {
        key: '绘绘杂谈回',
        name: '绘绘杂谈回',
        picture: '/image/80001200.jpg',
        effect: (
            <>
                [魔法|仪式]<br />
                「小铃久绘」仪式怪兽的降临必需。这个卡名的②的效果1回合只能使用1次。<br />
                ①：等级合计直到变成和仪式召唤的怪兽相同为止，把自己的手卡·场上的怪兽解放，从自己的手卡·墓地把1只「小铃久绘」仪式怪兽仪式召唤。<br />
                ②：这张卡在墓地中存在，从自己的场上把1只「小铃久绘」怪兽解放才能发动。这张卡加入手卡。<br />
            </>
        )
    },

    {
        key: '绘绘休息日',
        name: '绘绘休息日',
        picture: '/image/80001900.jpg',
        effect: (
            <>
                [魔法|场地]<br />
                这个卡名的③的效果1回合只能使用1次。<br />
                ①：自己场上的「小铃久绘」怪兽的攻击力·守备力上升500。<br />
                ②：仪式魔法卡的效果的发动以及那些发动的效果不会被无效化。<br />
                ③：自己场上有「小铃久绘」仪式怪兽仪式召唤的场合，以对方场上1张卡为对象才能发动。那张卡除外。<br />
            </>
        )
    },
    {
        key: '绘绘直播事故',
        name: '绘绘直播事故',
        picture: '/image/80002000.jpg',
        effect: (
            <>
                [陷阱]<br />
                这个卡名的①的效果1回合只能使用1次。<br />
                ①：自己场上有「小铃久绘」怪兽时才能发动。选自己场上最多5只「小铃久绘」怪兽，那些怪兽除外。那之后，那些怪兽回到场上。<br />
            </>
        )
    },
    {
        key: '小铃久绘-牵牛花',
        name: '小铃久绘-牵牛花',
        picture: '/image/80003000.jpg',
        effect: (
            <>
                [怪兽|效果|仪式] 天使/神<br />
                [★12] 2500/3000<br />
                「小铃久绘」仪式魔法卡降临。这张卡不用仪式召唤不能特殊召唤。这张卡不能作为超量召唤的素材。<br />
                ①：这张卡不会被战斗破坏。<br />
                ②：只要这张卡在场上存在，双方不能把12星以外的怪兽召唤。<br />
            </>
        )
    },
    {
        key: '小铃久绘-白鹿灵',
        name: '小铃久绘-白鹿灵',
        picture: '/image/80003100.jpg',
        effect: (
            <>
                [怪兽|效果|仪式] 天使/神<br />
                [★12] 3000/2500<br />
                「小铃久绘」仪式魔法卡降临。这张卡不用仪式召唤不能特殊召唤。这张卡不能作为超量召唤的素材。<br />
                ①：这张卡不会成为效果的对象，不会被效果破坏。<br />
                ②：只要这张卡在场上存在，和这张卡相同纵列的对方的魔法·陷阱卡的效果无效化。<br />
                ③：1回合1次，对方把魔法·陷阱·怪兽的效果发动时，从手卡把1只「小铃久绘」怪兽送去墓地才能发动。那个发动无效并破坏。<br />
            </>
        )
    },
]
export default cards
