import {Layout, Menu, Divider, Button, Table, Image} from 'antd'
import Styles from './style.module.scss'
import {DownloadOutlined, MobileOutlined} from '@ant-design/icons'
import cards from '../../data/cards'
import {useIsMobile} from '../../hook/useIsMobile'

const {Header, Content, Footer} = Layout


const HomePage = () => {
    const {isMobile} = useIsMobile()
    const columns = [
        {
            title: '卡图',
            dataIndex: 'picture',
            key: 'picture',
            width: isMobile ? 64 : 128,
            render: url => <Image src={url}></Image>,
        },
        {
            title: '卡名',
            dataIndex: 'name',
            key: 'name',
            width: isMobile ? 64 : 192,
            render: name => <div>{name}</div>,
        },
        {
            title: '效果',
            dataIndex: 'effect',
            key: 'effect',
            render: effect => <div>{effect}</div>,
        }
    ]
    return (
        <Layout className={Styles.layout}>
            <Header>
                <div className="title">YGOPro 社绘人服</div>
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={'0'}>
                    <Menu.Item key={'0'}>首页</Menu.Item>
                </Menu>
            </Header>
            <Content className="content">
                <div className="cover">
                    <div>
                        <h2>YGOPro 社绘人服</h2>
                        <p>这里是YGOPro 社绘人服，一个游戏王YGOPro的联机对战服务器。</p>
                        <p>本服务器支持随机对战与约战，不需要注册用户。</p>
                        <p>本服务器支持小铃久绘扩展卡包。</p>
                    </div>
                    <Image src="/imgs/cover.png" preview={false} height={150}></Image>
                </div>
                <Divider />
                <h2>使用方法</h2>
                <p>在YGOPro的联机模式中，在主机信息处分别输入<code>ygosrv.kosuzu-hisae.moe</code>和<code>7911</code>，不输入主机密码，点击加入游戏，即可进入YGOPro
                    社绘人服随机对战。</p>
                <p>也可在主机密码处输入任意文字，点击加入游戏，以那个文字为房间名建立房间，让你的对手输入同样的文字，即可加入房间。</p>
                <p>YGOPro
                    社绘人服由<a href="https://github.com/KosuzuHisaeProject">小铃久绘项目组</a>使用<a href="https://github.com/mycard/srvpro">SRVPro</a>搭建并维护。SRVPro是一个由萌卡开发的开源项目，以AGPL协议发布，源码托管在<a href="https://github.com/mycard/srvpro">GitHub</a>。
                </p>
                <h3><b>电脑版补丁使用方法</b></h3>
                <p>将补丁解压至游戏文件夹即可</p>
                <h3><b>手机版补丁使用方法</b></h3>
                <p>1. 下载ygomobile.ypk，用YGOMobile打开</p>
                <p>2. 初次安装时需要开启使用额外卡库</p>
                <p>3. 重启YGOMobile</p>
                <Divider />
                <h2>资源下载</h2>
                <h3><b>游戏下载</b></h3>
                <div className="downloads">
                    <Button href="https://ygo233.com/download/ygopro"><DownloadOutlined />游戏王YGOPro原版软件下载</Button>
                    <Button href="https://www.pgyer.com/ygomobilecn"><MobileOutlined />安卓手机版YGOMobile下载</Button>
                </div>
                <h3><b>补丁下载</b></h3>
                <div className="downloads">
                    <Button href="/ygopro.zip"><DownloadOutlined />下载补丁</Button>
                    <Button href="/ygomobile.ypk"><DownloadOutlined />下载补丁(YGOMobile)</Button>
                    <Button href="/ygopro2.zip"><DownloadOutlined />下载补丁(YGOPro2)</Button>
                </div>
                <Divider />
                <h2>卡片列表</h2>
                <Table columns={columns} dataSource={cards} bordered={true} pagination={false} />
            </Content>
            <Footer>©2022 <a href="https://github.com/KosuzuHisaeProject">小铃久绘项目组</a></Footer>
        </Layout>
    )
}
export default HomePage
