import {useState, useEffect} from 'react'

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640)

    const updateStatus = () => {
        setIsMobile(window.innerWidth <= 640)
    }
    useEffect(() => {
        window.addEventListener('resize', updateStatus)
        return () => window.removeEventListener('resize', updateStatus)
    }, [])
    return {isMobile}
}
